<template>
  <div class="card p-2">
    <!-- {{ dashboard_details.risk_vendors }} -->

    <b-tabs>
      <b-tab title="Overview" active>
        <div class="row mt-2">
          <div class="col-6 h-100">
            <div class="card p-1">
              <PieChart :alldata="dashboard_details.risk_vendors" />
            </div>
          </div>
          <div class="col-6">
            <div class="card p-1">
              <b class="mb-1">Top 5 Third Parties with Higher Risk</b>
              <div>
                <div
                  v-for="thirdparty in this.risksList"
                  :key="thirdparty._id"
                  style="width: 100%"
                  class="d-flex justify-content-between align-items-center list"
                >
                  <div>{{ thirdparty.name }}</div>
                  <div>
                    <span
                      class="text-success"
                      v-if="thirdparty.type == 'Low'"
                      >{{ thirdparty.type }}</span
                    >
                    <span
                      class="text-danger"
                      v-if="thirdparty.type == 'High'"
                      >{{ thirdparty.type }}</span
                    >
                    <span
                      class="text-warning"
                      v-if="thirdparty.type == 'Medium'"
                      >{{ thirdparty.type }}</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <div class="card p-1">
              <DonutChart :values="assessments" />
            </div>
          </div>
          <div class="col-6 h-100 mt-2">
            <div class="card p-1 mt-1">
              <div class="card p-1 assessment_progress_card">
                <p style="color: #000"><b>Assessment in Progress</b></p>
                <h1 class="lfont text-success">
                  {{ assessments[0] }}
                </h1>
              </div>
              <div class="card p-1 mt-50 completed_assessment_card">
                <p style="color: #000"><b>Closed Assessments</b></p>
                <h1 class="lfont" style="color: #000">{{ assessments[1] }}</h1>
              </div>
              <div class="card p-1 mt-50 Total_assessment_card">
                <p style="color: #000"><b>Total Assessments</b></p>
                <h1 class="lfont" style="color: #072cac">
                  {{ assessments[1] + assessments[0] }}
                </h1>
              </div>
            </div>
          </div>
        </div>
      </b-tab>
      <b-tab title="Ongoing Assessments">
        <div
          class="high-risk-listd-flex justify-content-between align-items-center"
        >
          <div v-if="this.pendingAssessments.length == 0">
            No Pending Assessments
          </div>
          <div v-else>
            <table class="table" style="border: 1px solid #ccc">
              <thead class="thead-light">
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Title</th>
                  <th scope="col">Type</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(pending, index) in this.pendingAssessments"
                  :key="pending._id"
                >
                  <th scope="row">{{ index + 1 }}</th>
                  <td>{{ pending.title }}</td>
                  <td>
                    <span class="text-danger" v-if="pending.status == 3"
                      >High</span
                    >
                    <span class="text-warning" v-if="pending.status == 2"
                      >Medium</span
                    >
                    <span class="text-success" v-if="pending.status == 1"
                      >Low</span
                    >
                  </td>
                </tr>
              </tbody>
            </table>
            <b-pagination
              v-model="pagination.page"
              :per-page="pagination.perPage"
              :total-rows="pagination.lastPage * pagination.perPage"
              aria-controls="my-table"
              class="mt-1 float-right"
            ></b-pagination>
          </div>
        </div>
      </b-tab>
      <b-tab title="Closed Assessments">
        <div
          class="high-risk-listd-flex justify-content-between align-items-center"
        >
          <div v-if="this.closedAssessments.length == 0">
            No Closed Assessments
          </div>
          <div v-else>
            <table class="table" style="border: 1px solid #ccc">
              <thead class="thead-light">
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Title</th>
                  <th scope="col">Type</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="closed in this.closedAssessments" :key="closed._id">
                  <th scope="row">{{ index + 1 }}</th>
                  <td>{{ closed.title }}</td>
                  <td>
                    <span class="text-danger" v-if="closed.status == 3"
                      >High</span
                    >
                    <span class="text-warning" v-if="closed.status == 2"
                      >Medium</span
                    >
                    <span class="text-success" v-if="closed.status == 1"
                      >Low</span
                    >
                  </td>
                </tr>
              </tbody>
            </table>
            <b-pagination
              v-model="closed.page"
              :per-page="closed.perPage"
              :total-rows="closed.lastPage * closed.perPage"
              aria-controls="my-table"
              class="mt-1 float-right"
            ></b-pagination>
          </div>
        </div>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import PieChart from "./components/pie_chart.vue";
import DonutChart from "./components/donut_chart.vue";
import UserMixins from "@/mixins/UserMixins";
import DashboardMixins from "../../mixins/DashboardMixins";
import moment from "moment";
import {
  BTable,
  BAvatar,
  BBadge,
  BButton,
  BTabs,
  BTab,
  BPagination,
} from "bootstrap-vue";
import ResponseMixins from "../../mixins/ResponseMixins";
import ThirdPartyRisksMixins from "../../mixins/ThirdPartyRisksMixins";
import store from "@/store";
export default {
  components: {
    BTable,
    BAvatar,
    BBadge,
    moment,
    BButton,
    DonutChart,
    BTabs,
    BTab,
    PieChart,
    BPagination,
  },
  data() {
    return {
      dashboard_details: [],
      assessments: [],
      TotalAssessments: null,
      pendingAssessments: [],
      closedAssessments: [],
      risksList: [],
      pagination: {
        lastPage: 1,
        totalRows: 1,
        perPage: 10,
        currentPage: 1,
        limit: 10,
      },
      closed: {
        lastPage: 1,
        totalRows: 1,
        perPage: 10,
        currentPage: 1,
        limit: 10,
      },
    };
  },
    watch: {
        pagination: {
        handler: function (newValue) {
          this.getAllInprogressAssessments(newValue);
        },
        deep: true,
      },
      closed: {
        handler: function (newValue) {
          this.getAllClosedAssessments(newValue);
        },
        deep: true,
      },
    },

    computed:{

      vendor_id(){
        return this.$store.state.app.user.vendor_id
      }
    },
    
  mounted() {
    this.load();
    // this.vendor_id = store.state.app.user.vendor_id;
    console.log('Vendor Id', this.vendor_id)
    this.getAllInprogressAssessments(this.vendor_id);
    this.getAllClosedAssessments(this.vendor_id)
  },

  mixins: [UserMixins, ResponseMixins, ThirdPartyRisksMixins, DashboardMixins],
  methods: {
    getAllInprogressAssessments() {
      if (this.vendor_id) {
        this.getPendingAssessments(this.vendor_id);
        // this.getClosedAssessment(vendor_id);
      } else {
        this.getPendingAssessments();
        // this.getClosedAssessment();
      }
    },
    getAllClosedAssessments() {
      if (this.vendor_id) {
        // this.getPendingAssessments(vendor_id);
        this.getClosedAssessment(this.vendor_id);
      } else {
        // this.getPendingAssessments();
        this.getClosedAssessment();
      }
    },
    load() {
      this.setDashboardDetails();
    },
    setDashboardDetails() {
      this.getDashboardDetails()
        .then((res) => {
          this.dashboard_details = res.data.data;
          this.sortedRisks();
          //   for (let value of Object.values(this.dashboard_details.assessments)) {
          //     console.log(value);
          //   }
          Object.fromEntries(
            Object.entries(this.dashboard_details.assessments).map(
              ([key, value]) => {
                this.assessments.push(value);
              }
            )
          );

          console.log(this.newArray);
        })
        .catch((err) => {});
    },
    sortedRisks() {
      let newArray = [];

      for (let key in this.dashboard_details.risk_vendors) {
        let array = this.dashboard_details.risk_vendors[key];
        let keyword = "";

        if (key === "1") {
          keyword = "Low";
        } else if (key === "2") {
          keyword = "Medium";
        } else if (key === "3") {
          keyword = "High";
        }
        for (let i = 0; i < array.length; i++) {
          let item = array[i];
          item.type = keyword;
          newArray.push(item);
          if (newArray.length === 5) {
            break;
          }
        }
        if (newArray.length === 5) {
          break;
        }
      }
      newArray.sort((a, b) => {
        const order = { High: 1, Medium: 2, Low: 3 };
        return order[a.keyword] - order[b.keyword];
      });
      this.risksList = newArray;
    },
    getPendingAssessments(vendor_id) {
      //   console.log(this.$store.state.app.user);
      this.getPendingAssessment(vendor_id)
        .then((res) => {
          this.pendingAssessments = res.data.data.data;
          this.pagination.lastPage = res.data.data.last_page;
          this.pagination.totalRows = res.data.data.total;
          this.pagination.page = res.data.data.current_page;
          this.pagination.limit = res.data.data.per_page;
        })
        .catch((err) => {});
    },
    getClosedAssessments(vendor_id) {
      this.getClosedAssessment(vendor_id)
        .then((res) => {
          this.closedAssessments = res.data.data.data;
          this.closed.lastPage = res.data.data.last_page;
          this.closed.totalRows = res.data.data.total;
          this.closed.page = res.data.data.current_page;
          this.closed.limit = res.data.data.per_page;
        })
        .catch((err) => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.misc-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.lfont {
  font-size: 40px;
  font-weight: 600;
}
.assessment_progress_card {
  background: #b1fdc6;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24) !important;
}
.completed_assessment_card {
  background: #ff9f43ad;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24) !important;
}
.Total_assessment_card {
  background: #8baff7;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24) !important;
}
.list {
  border-radius: 2px;
  padding: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  margin-bottom: 6px;
}
</style>

