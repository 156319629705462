export default{
    methods:{
        getDashboardDetails(){
            return new Promise((resolve, reject) => {
                const options = {
                    method: "GET",
                    headers: { "content-type": "application/x-www-form-urlencoded" },
                    url: `${process.env.VUE_APP_BASEURL}/dashboard`,
                };
                this.$http(options)
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    });
            })
        },
        getPendingAssessment(vendorid){
            return new Promise((resolve, reject) => {
              
                    const options = {
                        method: "GET",
                        headers: { "content-type": "application/x-www-form-urlencoded" },
                        url: vendorid?`${process.env.VUE_APP_BASEURL}/vendors/${vendorid}/assessments?status=1  `:`${process.env.VUE_APP_BASEURL}/tpr/assessments?status=1 `,
                    };
                this.$http(options)
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    });
            })
        },
        getClosedAssessment(vendorid){
            return new Promise((resolve, reject) => {
                const options = {
                    method: "GET",
                    headers: { "content-type": "application/x-www-form-urlencoded" },
                    url: vendorid?`${process.env.VUE_APP_BASEURL}/vendors/${vendorid}/assessments?status=2`:`${process.env.VUE_APP_BASEURL}/tpr/assessments?status=2 `,
                };
                this.$http(options)
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    });
            })
        },
        
    } 
}