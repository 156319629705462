<template>
  <b-card no-body>
    <b class="mb-1">Third Party Against Risk Category Summary</b>
    
    <b-card-body
      class="border rounded"
      style="
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
      "
    >
      <!-- apex chart -->
      <vue-apex-charts
        type="pie"
        height="335"
        class="mt-2 mb-1"
        :options="customersPie.chartOptions"
        :series="pievalues"
      />
      <!-- list group -->
      <div class="pt-25">
        <div
          v-for="(data, index) in chartData.listData"
          :key="index"
          class="d-flex justify-content-between"
          :class="
            index === Object.keys(chartData.listData).length - 1 ? '' : 'mb-1'
          "
        >
          <div class="series-info">
            <feather-icon
              :icon="data.icon"
              size="16"
              class="mr-50"
              :class="data.iconColor"
            />
            <span class="font-weight-bolder">{{ data.text }}</span>
          </div>
          <span>{{ data.result }}</span>
        </div>
      </div>
    </b-card-body>
  </b-card>
</template> 
    <script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BDropdown,
  BDropdownItem,
  BCardBody,
} from "bootstrap-vue";

import VueApexCharts from "vue-apexcharts";

import { $themeColors } from "@themeConfig";

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BDropdown,
    BDropdownItem,
    BCardBody,
    VueApexCharts,
  },

  props: {
    values: {
      type: Array,
      required: true,
    },
    alldata: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      chartData: {},
      customersPie: {
        series: [690, 258, 149],
        chartOptions: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          labels: ["Low", "Medium", "High"],
          dataLabels: {
            enabled: true,
          },
          legend: { show: true },

          stroke: {
            width: 1,
          },
          colors: [
            $themeColors.success,
            $themeColors.warning,
            "#a020f0", // '#991D28',
          ],
          
        },
       
      },
      piedata:this.alldata,
      pievalues:[],
    };
  },
  watch:{
    alldata(){
      this.getPieData()
    }
    
  },
  methods:{
    getPieData(){
      Object.fromEntries(
            Object.entries(this.alldata).map(
              ([key, value]) => {
                this.pievalues.push(value.length);
              }
            )
          );
      console.log(this.alldata)
      
    }
  }
};
</script>