<template>
  <b-card no-body>
    <b class="mb-1">Assessment Summary</b>
     <b-card-body class="border rounded" style=" box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);">
      <!-- apex chart -->
      <vue-apex-charts
        type="donut"
        height="335"
        class="mt-2 mb-1"
        :options="customersPie.chartOptions"
        :series="values"
      />
      <!-- list group -->
      <div class="pt-25">
        <div
          v-for="(data, index) in chartData.listData"
          :key="index"
          class="d-flex justify-content-between"
          :class="
            index === Object.keys(chartData.listData).length - 1 ? '' : 'mb-1'
          "
        >
          <div class="series-info">
            <feather-icon
              :icon="data.icon"
              size="16"
              class="mr-50"
              :class="data.iconColor"
            />
            <span class="font-weight-bolder">{{ data.text }}</span>
          </div>
          <span>{{ data.result }}</span>
        </div>
      </div>
    </b-card-body>
  </b-card>
</template> 
      <script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BDropdown,
  BDropdownItem,
  BCardBody,
} from "bootstrap-vue";

import VueApexCharts from "vue-apexcharts";

import { $themeColors } from "@themeConfig";

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BDropdown,
    BDropdownItem,
    BCardBody,
    VueApexCharts,
  },

  props: {
    values: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      chartData: {},
      customersPie: {
        series: [20, 12, 50],
        chartOptions: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          labels: ["In Progress", "Closed"],
          dataLabels: {
            enabled: true,
          },
          legend: { show: true },

          stroke: {
            width: 0,
          },
          colors: [
            $themeColors.success,
            $themeColors.warning,
            $themeColors.primary,
            "#a020f0", // '#991D28',
          ],
        },
      },
    };
  }, // methods: { //   onOrgChange(e) { //     this.chartOptions = { //       theme: { //         palette: e.target.value, //       }, //     }; //   }, // },
};
</script>